import React, { useMemo } from "react";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { Checkbox } from "baseui/checkbox";
import { Radio, RadioGroup } from "baseui/radio";
import { FormControl } from "baseui/form-control";
import { Select, Value } from "baseui/select";
import { Button } from "baseui/button";
import { APP_ENV } from "../../../constants/constants";
import { RewardAccount } from "../../../constants/enums";
import useRewardCountries from "../../../queries/reward/useRewardCountries";
import { countryCodes } from "../../../constants/country-codes";

const RewardEnabled = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="reward_enabled"
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    label={<span className="font-bold text-3xl">Rewards</span>}
                    caption="Sets whether the rewards feature is enabled or not"
                >
                    <RadioGroup
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        align="horizontal"
                    >
                        <Radio value="0">Off</Radio>
                        <Radio value="1">Setup</Radio>
                        <Radio value="2">Enabled</Radio>
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

const RewardAccountSelect = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="reward_account_enabled"
            control={control}
            render={({ field: { value, onChange } }) => (
                <>
                    <Checkbox
                        checked={value === RewardAccount.Runa || value === RewardAccount.All}
                        onChange={(e) =>
                            onChange(
                                e.target.checked
                                    ? value + RewardAccount.Runa
                                    : value - RewardAccount.Runa,
                            )
                        }
                    >
                        Enable Runa (Formerly WeGift)
                    </Checkbox>
                    <Checkbox
                        checked={value === RewardAccount.Plum || value === RewardAccount.All}
                        onChange={(e) =>
                            onChange(
                                e.target.checked
                                    ? value + RewardAccount.Plum
                                    : value - RewardAccount.Plum,
                            )
                        }
                    >
                        Enable Plum
                    </Checkbox>
                </>
            )}
        />
    );
};

const GroupRewardEnabled = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="grouped_reward"
            control={control}
            render={({ field: { value, onChange } }) => (
                <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}>
                    <div>
                        <p>Enable group rewards?</p>
                        <span className="font-normal">
                            This allows users to send rewards to a whole group rather than only
                            individuals
                        </span>
                    </div>
                </Checkbox>
            )}
        />
    );
};

const RewardersCanViewAllRewards = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="rewarders_can_view_all_rewards"
            control={control}
            render={({ field: { value, onChange } }) => (
                <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}>
                    <div>
                        <p>Rewarders can view all rewards?</p>
                        <span className="font-normal">
                            This setting allows anyone who is able to give a reward to also view an
                            entire list of all rewards that have been previously given
                        </span>
                    </div>
                </Checkbox>
            )}
        />
    );
};

const CustomRewardsStoreRadio = () => {
    const { control } = useFormContext();

    return (
        <Controller
            name="customer_reward_store_enabled"
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    label="Customer Reward Store"
                    caption="Adds ability for the customer to define their own things which can be redeemed
                    from the store"
                >
                    <RadioGroup
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        align="horizontal"
                    >
                        <Radio value="0">Off</Radio>
                        <Radio value="1">Setup</Radio>
                        <Radio value="2">Enabled</Radio>
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

const PointsEnabledRadio = ({ shouldDisableOnRender }: { shouldDisableOnRender: boolean }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name="points_enabled"
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    label="Customer rewards type"
                    caption={
                        <div>
                            Determines whether a customer uses a traditional FIAT currency or points
                            based.{" "}
                            <span className="!text-red-600 !font-bold">
                                Once set to points, this is not reversible.
                            </span>
                        </div>
                    }
                >
                    <RadioGroup
                        disabled={shouldDisableOnRender}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        align="horizontal"
                    >
                        <Radio value="0">Traditional FIAT currency</Radio>
                        <Radio value="1">Points based rewards</Radio>
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

const DefaultCountrySelect = () => {
    const { control } = useFormContext();
    const { data } = useRewardCountries();

    const options = useMemo(
        () =>
            data?.reward_countries?.map((country) => ({
                id: country.country_code,
                label: countryCodes[country.country_code],
            })) ?? [],
        [data],
    );

    return (
        <Controller
            name="reward_default_country"
            control={control}
            render={({ field: { value, onChange } }) => (
                <FormControl
                    label="Default Country"
                    caption="New users created without a country will be assigned this currency and country, all people will be flagged as having this default in admin"
                >
                    <Select
                        value={value}
                        onChange={({ value }) => onChange(value)}
                        clearable={false}
                        options={options}
                    />
                </FormControl>
            )}
        />
    );
};

interface RewardConfigFormValues {
    reward_enabled: string;
    customer_reward_store_enabled: string;
    grouped_reward: boolean;
    rewarders_can_view_all_rewards: boolean;
    reward_account_enabled: number;
    reward_default_country: Value;
    points_enabled: string;
}

interface CustomerRewardConfigFormProps {
    onSubmit: (values: RewardConfigFormValues) => void;
    initialValues: RewardConfigFormValues;
}

const CustomerRewardConfigForm: React.FC<CustomerRewardConfigFormProps> = ({
    initialValues,
    onSubmit,
}) => {
    const formMethods = useForm<RewardConfigFormValues>({
        defaultValues: initialValues,
    });

    return (
        <FormProvider {...formMethods}>
            <form className="my-6" onSubmit={formMethods.handleSubmit(onSubmit)}>
                <RewardEnabled />
                <hr className="my-4" />
                <p className="font-bold text-lg">
                    Please select the type of rewards you wish to offer in your account.
                </p>
                <p className="mb-4">
                    Remember, this is a{" "}
                    <span className="font-bold">
                        <span className="text-red-600">live</span> account,
                    </span>
                    {APP_ENV !== "production" ? (
                        <span>
                            {" "}
                            as this is in the production environment,{" "}
                            <span className="font-bold text-red-600">
                                REAL MONEY will be spent.
                            </span>
                        </span>
                    ) : (
                        " but as this is not the production environment, no real money will be spent."
                    )}
                </p>
                <RewardAccountSelect />
                <hr className="my-4" />
                <GroupRewardEnabled />
                <hr className="my-4" />
                <RewardersCanViewAllRewards />
                <hr className="my-4" />
                <CustomRewardsStoreRadio />
                <hr className="my-4" />
                <PointsEnabledRadio
                    shouldDisableOnRender={!!Number(initialValues?.points_enabled)}
                />
                <hr className="my-4" />
                <p className="text-lg font-bold">Currencies & Balance</p>
                <DefaultCountrySelect />
                <Button type="submit">Save Changes</Button>
            </form>
        </FormProvider>
    );
};

export default CustomerRewardConfigForm;
