import React, { useMemo } from "react";
import { Value } from "baseui/select";
import { styled } from "baseui";
import CustomerSelect from "../../../components/selects/CustomerSelect";
import {
    useGetCustomerRewardConfiguration,
    usePutCustomerRewardConfiguration,
} from "../../../queries/customers/useCustomerRewardConfiguration";
import CustomerRewardConfigForm from "./CustomerRewardConfigForm";
import { countryCodes } from "../../../constants/country-codes";

const RewardConfigWrapper = styled("main", () => ({
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "1rem",
}));

const CustomerRewardConfig = () => {
    const { mutate: updateConfig } = usePutCustomerRewardConfiguration();
    const [customer, setCustomer] = React.useState<Value>([]);

    const {
        data: configuration,
        isLoading,
        isSuccess,
    } = useGetCustomerRewardConfiguration({
        id: customer[0]?.id as string,
    });

    const initialFormValues = useMemo(
        () => ({
            reward_enabled: `${configuration?.reward_enabled ?? 0}`,
            customer_reward_store_enabled: `${configuration?.customer_reward_store_enabled ?? 0}`,
            grouped_reward: configuration?.grouped_reward ?? false,
            rewarders_can_view_all_rewards: configuration?.rewarders_can_view_all_rewards ?? false,
            reward_account_enabled: configuration?.reward_account_enabled ?? 0,
            reward_default_country: [
                {
                    id: configuration?.reward_default_country ?? "GB",
                    label: countryCodes[configuration?.reward_default_country ?? "GB"],
                },
            ],
            points_enabled: configuration?.reward_points_configuration?.enabled ? "1" : "0",
        }),
        [configuration],
    );

    return (
        <RewardConfigWrapper>
            <h1 className="text-2xl font-bold mb-4">Customer Reward Configurations</h1>
            <CustomerSelect value={customer} onChange={(value) => setCustomer(value)} />
            {isLoading && customer.length > 0 && <p className="py-4">Loading...</p>}
            {isSuccess && initialFormValues && configuration && (
                <CustomerRewardConfigForm
                    onSubmit={(values) =>
                        updateConfig({
                            id: customer[0]?.id as string,
                            payload: {
                                ...configuration,
                                reward_enabled: parseInt(values.reward_enabled, 10),
                                reward_account_enabled: values.reward_account_enabled,
                                grouped_reward: values.grouped_reward,
                                rewarders_can_view_all_rewards:
                                    values.rewarders_can_view_all_rewards,
                                customer_reward_store_enabled: parseInt(
                                    values.customer_reward_store_enabled,
                                    10,
                                ),
                                reward_default_country: values.reward_default_country[0]
                                    .id as string,
                                reward_points_configuration: {
                                    ...configuration.reward_points_configuration,
                                    enabled: !!Number(values.points_enabled),
                                },
                            },
                        })
                    }
                    initialValues={initialFormValues}
                />
            )}
        </RewardConfigWrapper>
    );
};

export default CustomerRewardConfig;
