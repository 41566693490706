import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { toaster } from "baseui/toast";
import { API_CUSTOMER_REWARD_CONFIG } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import type { CustomerRewardConfiguration } from "../../types/customer";

const useGetCustomerRewardConfiguration = ({
    id,
}: {
    id?: string;
}): UseQueryResult<CustomerRewardConfiguration> =>
    useQuery({
        queryKey: ["customer-reward-configuration", id],
        queryFn: async () => {
            const url = new URL(API_CUSTOMER_REWARD_CONFIG({ id: id as string }));

            const request = new AuthenticatedMoApiRequest(url);

            return FetchResponse(request);
        },
        enabled: !!id,
    });

const usePutCustomerRewardConfiguration = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            id,
            payload,
        }: {
            id: string;
            payload: CustomerRewardConfiguration;
        }) => {
            const url = new URL(API_CUSTOMER_REWARD_CONFIG({ id }));

            const request = new AuthenticatedMoApiRequest(url, {
                method: "PUT",
                payload: payload as Partial<FormData>,
            });

            return FetchResponse(request);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["customer-reward-configuration"]);
            toaster.positive("Configuration updated successfully", {});
        },
    });
};

export { useGetCustomerRewardConfiguration, usePutCustomerRewardConfiguration };
