import { Select, Value } from "baseui/select";
import React from "react";
import useGetCustomers from "../../queries/customers/useGetCustomers";

interface CustomerSelectProps {
    value: Value | undefined;
    onChange: (value: Value) => void;
}

const CustomerSelect: React.FC<CustomerSelectProps> = ({ value, onChange }) => {
    const { data: customers, isLoading: isLoadingCustomers } = useGetCustomers();

    return (
        <Select
            value={value}
            options={
                customers?.Customers.map((c) => ({
                    label: c.name,
                    id: c.bson_id,
                    service_id: c.id,
                })) || []
            }
            isLoading={isLoadingCustomers}
            onChange={(params) => onChange(params.value)}
            placeholder="Select a customer"
        />
    );
};

export default CustomerSelect;
