import React from "react";
import { CUSTOMER_REWARD_CONFIG } from "../routes";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import MainLayout from "../../layouts/MainLayout";
import CustomerRewardConfig from "../../routes/customer/reward-config/CustomerRewardConfig";

const CustomerRouter = {
    path: CUSTOMER_REWARD_CONFIG,
    element: (
        <AuthenticationWrapper>
            <MainLayout />
        </AuthenticationWrapper>
    ),
    children: [{ index: true, element: <CustomerRewardConfig /> }],
};

export default CustomerRouter;
