import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { API_REWARD_COUNTRIES } from "../../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";

type RewardCountriesResponse = {
    reward_countries: RewardCountry[];
};

const useRewardCountries = (): UseQueryResult<RewardCountriesResponse> =>
    useQuery({
        queryKey: ["customer-reward-countries"],
        queryFn: async () => {
            const url = new URL(API_REWARD_COUNTRIES);

            const request = new AuthenticatedMoApiRequest(url);

            return FetchResponse(request);
        },
    });

export default useRewardCountries;
