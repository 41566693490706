import { Langs } from "./enums";

export const ACTION_CREATE = "create";
export const ACTION_EDIT = "edit";

export const PATH_BOOST_HEADINGS = "headings";
export const PATH_BOOST_CATEGORIES = "categories";
export const PATH_BOOST_TEMPLATES = "templates";
export const PATH_TRANSLATION_KEY = "key";

export const ROOT = "/";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const ACCESS_DENIED = "/error/access_denied";

export const CONNECT_WITH_TOKEN_ROOT = "/connect-with-token";
export const TRANSLATIONS_ROOT = "/translations";
export const BOOST_ROOT = "/boost";
export const BOOST_CMS_ROOT = "/boost/cms";
export const BOOST_CMS_HEADINGS_ROOT = `${BOOST_CMS_ROOT}/${PATH_BOOST_HEADINGS}`;
export const BOOST_CMS_CATEGORIES_ROOT = `${BOOST_CMS_ROOT}/${PATH_BOOST_CATEGORIES}`;
export const BOOST_CMS_TEMPLATES_ROOT = `${BOOST_CMS_ROOT}/${PATH_BOOST_TEMPLATES}`;
export const BOOST_CMS_HEADINGS_CREATE = `${BOOST_CMS_HEADINGS_ROOT}/${ACTION_CREATE}`;
export const BOOST_CMS_CATEGORIES_CREATE = `${BOOST_CMS_CATEGORIES_ROOT}/${ACTION_CREATE}`;
export const BOOST_CMS_TEMPLATES_CREATE = `${BOOST_CMS_TEMPLATES_ROOT}/${ACTION_CREATE}`;

export const CMS_ROOT = BOOST_CMS_TEMPLATES_ROOT;

export const BOOST_CMS_HEADINGS_EDIT = ({ id }: { id: string }) =>
    `${BOOST_CMS_HEADINGS_ROOT}/${id}/${ACTION_EDIT}`;
export const BOOST_CMS_CATEGORIES_EDIT = ({ id }: { id: string }) =>
    `${BOOST_CMS_CATEGORIES_ROOT}/${id}/${ACTION_EDIT}`;
export const BOOST_CMS_TEMPLATES_EDIT = ({ id }: { id: string }) =>
    `${BOOST_CMS_TEMPLATES_ROOT}/${id}/${ACTION_EDIT}`;
export const BOOST_CMS_TEMPLATES_BY_HEADING_ID = ({ id }: { id: string }) =>
    `${BOOST_CMS_TEMPLATES_ROOT}/${PATH_BOOST_HEADINGS}/${id}`;
export const BOOST_CMS_TEMPLATES_BY_CATEGORY_ID = ({ id }: { id: string }) =>
    `${BOOST_CMS_TEMPLATES_ROOT}/${PATH_BOOST_CATEGORIES}/${id}`;
export const TRANSLATIONS_EDIT = ({ id }: { id: string }) =>
    `${TRANSLATIONS_ROOT}/${id}/${ACTION_EDIT}`;
export const TRANSLATIONS_BY_KEY = ({ key }: { key: string }) =>
    `${TRANSLATIONS_ROOT}/${PATH_TRANSLATION_KEY}/${key}`;
export const TRANSLATIONS_BY_KEY_AND_LOCALE = ({ key, locale }: { key: string; locale: Langs }) =>
    `${TRANSLATIONS_ROOT}/${PATH_TRANSLATION_KEY}/${key}/${locale}`;

export const MERGE_DEV_ROOT = "/merge-dev";
export const PATH_GROUP_MAPPINGS = "group-mappings";
export const MERGE_DEV_GROUP_MAPPINGS = `${MERGE_DEV_ROOT}/${PATH_GROUP_MAPPINGS}`;

export const CUSTOMER_REWARD_CONFIG = "/customer-reward-config";
